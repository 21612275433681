.App {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #82d965;
    border-radius: 8px;
}
