.main_container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main_container > img {
    position: absolute;
    top: 0;
    transform: translateY(-20%);
    width: 100%;
    align-self: center;
}

.banner_bg {
    background-color: white;
    height: 10vh;
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15rem 0 10rem 0;
    gap: 5rem;
    align-items: center;
}

.container > h1 {
    text-align: center;
    font-size: 3rem;
    color: #111;
    font-weight: 600;
    font-family: "mudclaw";
}

.container::before {
    content: "";
    top: 0;
    bottom: 0;
    position: absolute;
    background: url(../../Images/SpeakersBg.png) no-repeat;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.container_row_1 {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    padding: 0rem 4rem;
}

.container_row_2 {
    display: flex;
    gap: 2rem;
    justify-self: center;
    padding: 0rem 4rem;
}

.box_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    height: 240px;
    border: 2px solid black;
    background: #fffff4;
    box-shadow: 8px 8px 0px black;
    padding: 2em 1em 1em 1em;
    justify-content: center;
    gap: 0.5rem;
    position: relative;
}

.box_main_top {
    background: black;
    height: 12%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.5em;
    gap: 0.5em;
}

.box_main_green_button,
.box_main_red_button,
.box_main_yellow_button {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.box_main_green_button {
    background-color: #269b4e;
}
.box_main_red_button {
    background-color: #e9493d;
}
.box_main_yellow_button {
    background-color: #fff052;
}

.box_main_container > p {
    text-align: center;
    width: 99%;
    color: black;
}

.box_main_container h1 {
    text-align: center;
    font-size: 1.25em;
    color: rgb(0, 0, 0);
}

.title_link_image {
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    position: relative;
}

.title_link_image > a {
    position: absolute;
    right: 0;
    transform: translateX(16px);
}

.box {
    padding: 1em;
    width: 240px;
    height: 240px;
    background-color: white;
    border: 2px solid black;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.box > h2 {
    text-align: center;
    color: #111;
    font-weight: 800;
    font-family: "mudclaw";
}

.box img,
.box_main img {
    width: 100%;
    height: 100%;
    border: 4px solid #111;
}

@media only screen and (max-width: 900px) {
    .container_row_1 {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        padding: 0rem 0rem;
    }
}
@media only screen and (max-width: 660px) {
    .container_row_2 {
        gap: 2rem;

        flex-direction: column;
        align-items: center;
        padding: 0rem 0rem;
    }
    .container {
        padding: 6rem 0 3rem 0;
        gap: 2rem;
    }
}
