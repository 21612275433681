#newsletter {
    padding: 20px;
    color: #ffffff;
    background: #35424a;
    height: 100px;
    overflow-x: hidden;
}

#newsletter .container h2 {
    float: left;
    margin-top: 12px;
    font-family: "Open Sans", sans-serif;
    font-size: 22px;
    margin-left: 20px;
}

.container_form form {
    float: right;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.container_form input {
    /* padding: 4px; */
    height: 40px;
    width: 250px;
    margin: 10px 40px;
    /* padding: 10px; */
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}

.container_form #button_1 {
    height: 40px;
    background: rgba(241, 28, 0, 0.911);
    border: none;
    /* padding: 12.5px; */
    padding: 0px 10px;
    /* margin-top: 10px; */
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    margin-right: 40px;
    color: whitesmoke;
    font-weight: 500;
    cursor: pointer;
}
